import {Response} from 'algoliasearch';
import type {AlgoliaObject, BrowseCondition} from '../../algolia';
import {
  ALPHA_LOCK_FW_VERSION_PREFIX,
  BleDeviceType,
  EPPH_BITKEY_EDITION,
  ED_LOCK_BITKEY_EDITION,
  FUKI_LOCK_PREFIX,
  HoleTypeGateForElevator,
  LogTargetReader,
  MULTILOCK_BITKEY_EDITION_PREFIX,
} from '../../../firebase/firestore/references/devices/firestore-devices';
import {EpochMillis} from '../../../common-types/numbers';
import {FirestoreDeviceHolesType} from '../../../firebase/firestore/references/devices/holes/firestore-device-holes';
import {ExchangeStep} from '../../../firebase/firestore/references/organizations/devices/firestore-organization-devices';
import {LegacyIndexBase} from '@algolia/v1/legacy-index-base';
import {LegacyIndexName} from '@algolia/v1/legacy-index-name';
import {AlgoliaFilters} from '../../algolia';

export enum AlgoliaDevicesAttributes {
  name = 'name',
  bkpObjectId = 'bkpObjectId',
  originDeviceId = 'originDeviceId',
  address = 'address',
  spaceId = 'spaceId',
  id = 'id',

  // ネスト系全部バラす。オブジェクト存在確認漏れが多すぎる
  firmware = 'firmware',
  model = 'model',
  is2Lock = 'is2Lock',
  bitkeyUdid = 'bitkeyUdid',
  battery = 'battery',
  serialNo = 'serialNo',

  addressCountry = 'addressCountry',
  addressPrefectures = 'addressPrefectures',
  addressCities = 'addressCities',
  addressSublocality = 'addressSublocality',
  addressOthers = 'addressOthers',
  deviceAddress = 'deviceAddress',
  readerIds = 'readerIds',
  connectorId = 'connectorId',
  bitlinkId = 'bitlinkId',

  // 認証デバイス
  linkAuthDeviceIds = 'linkAuthDeviceIds',
}

export interface AlgoliaDeviceHole {
  id: string;
  name: string;
}

export interface AlgoliaDevice extends AlgoliaObject {
  linkId: string;
  name: string;
  autolockSeconds: number;
  holes: AlgoliaDeviceHole[];
  coordinate: {
    latitude: number;
    longitude: number;
  };
  lastCurrentTimeSetAt: number;
  useHandsfreeUnlock: boolean;
  createdAt: number;
  spaceId: string;
  spaceName: string;

  // WifiConnectorと接続する時に使用
  deviceAddress: string;
  nearWifiConnectorIds: string[];

  address: string;
  addressCountry: string;
  addressPrefectures: string;
  addressCities: string;
  addressSublocality: string;
  addressOthers: string;

  firmware: string;
  model: BleDeviceType;
  is2Lock: boolean;
  bitkeyUdid: string;
  battery: number;
  serialNo: string;
  readerIds: string[];
  connectorId: string;
  bitlinkId: string;

  thingId?: string;
  linkAuthDeviceIds?: string[];

  insideReaders?: LogTargetReader[];
  outsideReaders?: LogTargetReader[];
  locked: boolean;
  lastUpdatedLockedStatusAt: EpochMillis;
  // オートロック設定用
  useMagnetSensor?: boolean;
  isRegisteredMagnetSensor?: boolean;
  lockOffWeeklyCondition: number[];
  lockOffStartTimes: EpochMillis;
  lockOffEndTimes: EpochMillis;

  exchangeStep?: ExchangeStep;
  beforeExchangedBitlockId?: string;
  afterExchangedBitlockId?: string;

  multipleHoles?: {[key in HoleTypeGateForElevator]: FirestoreDeviceHolesType[]};
}

const searchableAttributes = {
  name: AlgoliaDevicesAttributes.name,
  bkpObjectId: AlgoliaDevicesAttributes.bkpObjectId,
  originDeviceId: AlgoliaDevicesAttributes.originDeviceId,
  address: AlgoliaDevicesAttributes.address,
  firmware: AlgoliaDevicesAttributes.firmware,
  model: AlgoliaDevicesAttributes.model,
};

export default class AlgoliaDevices extends LegacyIndexBase<AlgoliaDevice> {
  protected name = LegacyIndexName.devices;
  protected rawIndexName: string;

  public static readonly searchableAttributes = searchableAttributes as Readonly<typeof searchableAttributes>;

  public static readonly facets = () => ({
    id: AlgoliaDevicesAttributes.id,
    name: AlgoliaDevicesAttributes.name,
    spaceId: AlgoliaDevicesAttributes.spaceId,
    firmware: AlgoliaDevicesAttributes.firmware,
    model: AlgoliaDevicesAttributes.model,
    bitkeyUdid: AlgoliaDevicesAttributes.bitkeyUdid,
    battery: AlgoliaDevicesAttributes.battery,
    serialNo: AlgoliaDevicesAttributes.serialNo,
    deviceAddress: AlgoliaDevicesAttributes.deviceAddress,
    readerIds: AlgoliaDevicesAttributes.readerIds,
    connectorId: AlgoliaDevicesAttributes.connectorId,
    bitlinkId: AlgoliaDevicesAttributes.bitlinkId,
    linkAuthDeviceIds: AlgoliaDevicesAttributes.linkAuthDeviceIds,
  });

  public static getIndex = (organizationId: string) => new AlgoliaDevices(organizationId);

  private constructor(organizationId: string) {
    super();
    this.rawIndexName = `${organizationId}_${this.name}`;
  }

  /**
   * 継承元のsearchを利用するために別名で定義
   * 継承先と同名のメソッドで存在していると、継承元のメソッドが呼び出せないため
   * @private
   */
  private _superSearch = this.search;
  private _superGetAllHitsByBrowseResponse = this.getAllHitsByBrowseResponse;
  private _superRelation = this.relation;

  public searchableAttributes = AlgoliaDevices.searchableAttributes;
  public facets = () => AlgoliaDevices.facets();

  // 基底クラスのAllHitsByBrowseResponseはレスポンスの型が違うため再利用出来ない。標準のsearchを利用してAllHitsByBrowseResponseの動作を模倣する。
  public getAllHitsByBrowseResponse = async (condition: BrowseCondition) =>
    this._superGetAllHitsByBrowseResponse(condition).then((hits: AlgoliaDevice[]) => this.convertFirmware(hits));

  /**
   * 以下、
   * fuki_lock > MULTILOCK Bitkey Edition への置換暫定対応
   * apha lock > ed lock or ePPH への 変換暫定対応
   * TODO DBちゃんと直ったら下記メソッド群抹殺する
   */
  // 20230315 ElasticSearch置換　基底クラスをそのまま利用できないため、基底クラスのsearch結果をoverideして返却
  public search = async (condition: BrowseCondition): Promise<Response<AlgoliaDevice>> =>
    this._superSearch(condition).then(res => ({...res, hits: this.convertFirmware(res.hits)}));
  public relation = async (filters: AlgoliaFilters): Promise<Response<AlgoliaDevice>> =>
    this._superRelation(filters).then(res => ({...res, hits: this.convertFirmware(res.hits)}));

  /**
   * ファームウェアのモデル情報を置換処理
   * @param hits
   * @returns hits コンバート後のhits
   */
  public convertFirmware(hits: AlgoliaDevice[]) {
    const convertedHits = hits.map(hit => {
      const resFuki = this.convertFukiToMultiLockEdition(hit);
      const resEdLock = this.convertAlphaEdLockToEdLockPLUS(resFuki);
      return this.convertAlphaEPPHLockToEPPHLockPLUS(resEdLock);
    });
    return convertedHits;
  }

  private convertFukiToMultiLockEdition = (h: AlgoliaDevice) => ({
    ...h,
    model: h.model === BleDeviceType.FUKI_LOCK ? BleDeviceType.MULTILOCK_BITKEY_EDITION : h.model,
    firmware:
      h.model === BleDeviceType.FUKI_LOCK && h.firmware.includes(FUKI_LOCK_PREFIX)
        ? h.firmware.replace(FUKI_LOCK_PREFIX, MULTILOCK_BITKEY_EDITION_PREFIX)
        : h.firmware,
  });
  /**
   * fuki_lock > MULTILOCK Bitkey Edition への置換暫定対応
   * ここまで
   */

  /**
   * 以下 暫定的にalpha ed lock, alpha ePPH lock -> edロックPLUS Bitkey Edition, ePPH Bitkey Edition へ変換処理
   * FIXME: 現在, Version名が ed と ePPH で同じだった場合はこのままでOKだけど違うのがFW から来れば変更が必要
   * また,今回Versionは接頭句なしでVersion のみ表示ということだったのでPrefix 削除のみとした
   */
  private convertAlphaEdLockToEdLockPLUS = (h: AlgoliaDevice) => ({
    ...h,
    model: h.model === BleDeviceType.ALPHA_ED_LOCK ? (ED_LOCK_BITKEY_EDITION as BleDeviceType) : h.model,
    firmware:
      h.model === BleDeviceType.ALPHA_ED_LOCK && h.firmware.includes(ALPHA_LOCK_FW_VERSION_PREFIX)
        ? h.firmware.replace(ALPHA_LOCK_FW_VERSION_PREFIX, '')
        : h.firmware,
  });
  private convertAlphaEPPHLockToEPPHLockPLUS = (h: AlgoliaDevice) => ({
    ...h,
    model: h.model === BleDeviceType.ALPHA_EPPH_LOCK ? (EPPH_BITKEY_EDITION as BleDeviceType) : h.model,
    firmware:
      h.model === BleDeviceType.ALPHA_EPPH_LOCK && h.firmware.includes(ALPHA_LOCK_FW_VERSION_PREFIX)
        ? h.firmware.replace(ALPHA_LOCK_FW_VERSION_PREFIX, '')
        : h.firmware,
  });
  /**
   * alpha ed lock, alpha ePPH lock -> edロックPLUS Bitkey Edition, ePPH Bitkey Edition へ変換処理
   * ここまで
   */
}
